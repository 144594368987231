<template>
  <div>
    <v-card elevation="0">
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container class="pt-5">
            <v-row>
              <v-col md="6" sm="12" cols="12">
                <v-col>
                  <v-btn raised @click="LogoImagem">Carregar Logo</v-btn>
                </v-col>
                <v-row>
                  <input
                    type="file"
                    style="display: none"
                    ref="fileInput"
                    :disabled="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    accept="imagem/*"
                    @change="onFilePicked"
                  />
                </v-row>
                <v-row>
                  <v-col>
                    <v-img
                      class="mt-2"
                      max-height="300"
                      max-width="300"
                      :src="imagemLogo"
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </v-col>
              <!-- COLUNA DE DADOS -->
              <v-col md="6" sm="12" cols="12">
                <v-row>
                  <v-col md="6">
                    <validation-provider v-slot="{ errors }" vid="tipo_pessoa">
                      <v-radio-group
                        v-model="empresaNova.tipo_pessoa"
                        row
                        hide-details
                        :readonly="
                          $store.getters['usuario/temPermissao'](
                            'mostrar.empresas'
                          ) &&
                          !$store.getters['usuario/temPermissao'](
                            'editar.empresas'
                          )
                        "
                        :error-messages="errors"
                        :disabled="$store.state.layout.loading"
                        class="mt-1"
                      >
                        <v-radio label="Física" value="f" hide-details="auto" />
                        <v-radio
                          label="Jurídica"
                          value="j"
                          hide-details="auto"
                        />
                      </v-radio-group>
                    </validation-provider>
                  </v-col>
                  <v-col md="6" sm="12" class="mt-2">
                    <v-switch
                      v-model="empresaNova.ativo"
                      class="mt-0 pt-0"
                      color="success"
                      :readonly="
                        $store.getters['usuario/temPermissao'](
                          'mostrar.empresas'
                        ) &&
                        !$store.getters['usuario/temPermissao'](
                          'editar.empresas'
                        )
                      "
                      :disabled="$store.state.layout.loading"
                      label="Ativo"
                    />
                  </v-col>
                </v-row>
                <!--CPF/CNPJ-->
                <v-row>
                  <v-col md="6" sm="12">
                    <validation-provider
                      v-if="empresaNova.tipo_pessoa === 'f'"
                      v-slot="{ errors }"
                      vid="cpf_cnpj"
                    >
                      <v-text-field
                        v-model="empresaNova.cpf_cnpj"
                        v-mask="'###.###.###-##'"
                        outlined
                        :readonly="
                          $store.getters['usuario/temPermissao'](
                            'mostrar.empresas'
                          ) &&
                          !$store.getters['usuario/temPermissao'](
                            'editar.empresas'
                          )
                        "
                        dense
                        :disabled="$store.state.layout.loading"
                        hide-details="auto"
                        label="CPF"
                        :error-messages="errors"
                      />
                    </validation-provider>

                    <validation-provider
                      v-if="empresaNova.tipo_pessoa === 'j'"
                      v-slot="{ errors }"
                      vid="cpf_cnpj"
                    >
                      <v-text-field
                        v-model="empresaNova.cpf_cnpj"
                        v-mask="'##.###.###/####-##'"
                        outlined
                        :readonly="
                          $store.getters['usuario/temPermissao'](
                            'mostrar.empresas'
                          ) &&
                          !$store.getters['usuario/temPermissao'](
                            'editar.empresas'
                          )
                        "
                        :disabled="$store.state.layout.loading"
                        dense
                        hide-details="auto"
                        label="CNPJ"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col md="6" sm="12">
                    <validation-provider
                      v-slot="{ errors }"
                      vid="empresa.plano_id"
                    >
                      <autocomplete-plano
                        v-model="empresaNova.plano_id"
                        :readonly="
                          !$store.getters['usuario/temPermissao'](
                            'editar.planos'
                          )
                        "
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <!--RAZAO SOCIAL-->
                <v-row>
                  <v-col md="12" sm="12" cols="12">
                    <validation-provider v-slot="{ errors }" vid="nome">
                      <v-text-field
                        v-model="empresaNova.nome"
                        outlined
                        dense
                        :readonly="
                          $store.getters['usuario/temPermissao'](
                            'mostrar.empresas'
                          ) &&
                          !$store.getters['usuario/temPermissao'](
                            'editar.empresas'
                          )
                        "
                        hide-details="auto"
                        :disabled="$store.state.layout.loading"
                        label="Razão Social"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <!--FANTASIA-->
                <v-row>
                  <v-col md="12" sm="12" cols="12">
                    <validation-provider v-slot="{ errors }" vid="apelido">
                      <v-text-field
                        v-model="empresaNova.apelido"
                        outlined
                        :readonly="
                          $store.getters['usuario/temPermissao'](
                            'mostrar.empresas'
                          ) &&
                          !$store.getters['usuario/temPermissao'](
                            'editar.empresas'
                          )
                        "
                        dense
                        hide-details="auto"
                        :disabled="$store.state.layout.loading"
                        label="Nome Fantasia"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <h3 class="ma-2">Dados para contato</h3>

            <v-row>
              <v-col md="3" sm="12" cols="12">
                <validation-provider v-slot="{ errors }" vid="contato">
                  <v-text-field
                    v-model="empresaNova.contato"
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    :error-messages="errors"
                    label="Contato"
                  />
                </validation-provider>
              </v-col>
              <v-col md="3" sm="12" cols="12">
                <validation-provider v-slot="{ errors }" vid="Email">
                  <v-text-field
                    v-model="empresaNova.email"
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    :error-messages="errors"
                    label="Email"
                  />
                </validation-provider>
              </v-col>
              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="telefone">
                  <v-text-field
                    v-model="empresaNova.telefones[0]"
                    v-mask="'(##)#########'"
                    outlined
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    dense
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Telefone"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="telefone">
                  <v-text-field
                    v-model="empresaNova.telefones[1]"
                    v-mask="'(##)#########'"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Telefone"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <h3 class="mb-2 mt-2">Dados do endereço</h3>
            <v-row>
              <v-col md="6" cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  vid="endereco.logradouro"
                >
                  <v-text-field
                    v-model="empresaNova.endereco.logradouro"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    required
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Logradouro"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>

              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="complemento">
                  <v-text-field
                    v-model="empresaNova.endereco.complemento"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Complemento"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="bairro">
                  <v-text-field
                    v-model="empresaNova.endereco.bairro"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Bairro"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="endereco.numero">
                  <v-text-field
                    v-model="empresaNova.endereco.numero"
                    v-mask="'######'"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Número"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>

              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="endereco.cep">
                  <v-text-field
                    v-model="empresaNova.endereco.cep"
                    v-mask="'#####-###'"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="CEP"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="3" cols="12">
                <autocomplete-estados
                  :readonly="
                    $store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !$store.getters['usuario/temPermissao']('editar.empresas')
                  "
                  v-model="estadoId"
                />
              </v-col>

              <v-col md="3" cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  vid="endereco.cidade_id"
                >
                  <autocomplete-cidades
                    v-model="empresaNova.endereco.cidade_id"
                    :estado-id="estadoId"
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !$store.getters['usuario/temPermissao']('editar.empresas')
                    "
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-divider class="mt-4" />
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-left">
            <btn-salvar
              :temPermissao="
                $store.getters['usuario/temPermissao']('editar.empresas')
              "
              :carregando="carregandoSalvar"
              @click="$emit('salvar', empresaNova)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      imagemLogo: null,
      image: null,
      estadoId: null,
      empresaNova: {
        nome: null,
        apelido: null,
        tipo_pessoa: null,
        cpf_cnpj: null,
        contato: null,
        telefones: [null, null],
        ativo: true,
        imagem_logo: null,
        plano_id: null,
        email: null,
        endereco: {
          endereco_id: null,
          logradouro: null,
          bairro: null,
          numero: null,
          cep: null,
          cidade_id: null,
        },
        planoID: 0,
      },
    };
  },

  watch: {
    validacaoFormulario(val) {
      if (!val) return;

      return this.$refs.observer.setErrors(val);
    },

    empresa(empresa) {
      var endereco_id = null;
      if (_.isEmpty(empresa)) return false;
      this.estadoId = 23;
      if (!_.isEmpty(empresa.endereco.cidade)) {
        this.estadoId = empresa.endereco.cidade.estado_id;
        endereco_id = empresa.endereco.id;
      }

      empresa = { ...empresa, ...{ endereco: empresa.endereco } };
      this.empresaNova = empresa;
      this.imagemLogo = this.empresaNova.url_imagem;
      this.empresaNova.endereco.endereco_id = endereco_id;

      this.empresaNova.telefones[0] = empresa.telefones[0].numero_telefone;
      this.empresaNova.telefones[1] = empresa.telefones[1].numero_telefone;
    },
  },

  methods: {
    LogoImagem() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Por favor envie uma imagem válida");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imagemLogo = fileReader.result;
        this.empresaNova.imagem_logo = this.imagemLogo;
      });
      fileReader.readAsDataURL(files[0]);
      this.imagem = files[0];
    },
  },
};
</script>
