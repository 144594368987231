var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"mt-4"},[_c('v-container',{staticClass:"pt-5"},[_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"12","cols":"12"}},[_c('v-col',[_c('v-btn',{attrs:{"raised":""},on:{"click":_vm.LogoImagem}},[_vm._v("Carregar Logo")])],1),_c('v-row',[_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","disabled":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"accept":"imagem/*"},on:{"change":_vm.onFilePicked}})]),_c('v-row',[_c('v-col',[_c('v-img',{staticClass:"mt-2",attrs:{"max-height":"300","max-width":"300","src":_vm.imagemLogo}})],1)],1)],1),_c('v-col',{attrs:{"md":"6","sm":"12","cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"tipo_pessoa"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-radio-group',{staticClass:"mt-1",attrs:{"row":"","hide-details":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                          'mostrar.empresas'
                        ) &&
                        !_vm.$store.getters['usuario/temPermissao'](
                          'editar.empresas'
                        ),"error-messages":errors,"disabled":_vm.$store.state.layout.loading},model:{value:(_vm.empresaNova.tipo_pessoa),callback:function ($$v) {_vm.$set(_vm.empresaNova, "tipo_pessoa", $$v)},expression:"empresaNova.tipo_pessoa"}},[_c('v-radio',{attrs:{"label":"Física","value":"f","hide-details":"auto"}}),_c('v-radio',{attrs:{"label":"Jurídica","value":"j","hide-details":"auto"}})],1)]}}])})],1),_c('v-col',{staticClass:"mt-2",attrs:{"md":"6","sm":"12"}},[_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"color":"success","readonly":_vm.$store.getters['usuario/temPermissao'](
                        'mostrar.empresas'
                      ) &&
                      !_vm.$store.getters['usuario/temPermissao'](
                        'editar.empresas'
                      ),"disabled":_vm.$store.state.layout.loading,"label":"Ativo"},model:{value:(_vm.empresaNova.ativo),callback:function ($$v) {_vm.$set(_vm.empresaNova, "ativo", $$v)},expression:"empresaNova.ativo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"12"}},[(_vm.empresaNova.tipo_pessoa === 'f')?_c('validation-provider',{attrs:{"vid":"cpf_cnpj"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"outlined":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                          'mostrar.empresas'
                        ) &&
                        !_vm.$store.getters['usuario/temPermissao'](
                          'editar.empresas'
                        ),"dense":"","disabled":_vm.$store.state.layout.loading,"hide-details":"auto","label":"CPF","error-messages":errors},model:{value:(_vm.empresaNova.cpf_cnpj),callback:function ($$v) {_vm.$set(_vm.empresaNova, "cpf_cnpj", $$v)},expression:"empresaNova.cpf_cnpj"}})]}}],null,false,1521477118)}):_vm._e(),(_vm.empresaNova.tipo_pessoa === 'j')?_c('validation-provider',{attrs:{"vid":"cpf_cnpj"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"outlined":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                          'mostrar.empresas'
                        ) &&
                        !_vm.$store.getters['usuario/temPermissao'](
                          'editar.empresas'
                        ),"disabled":_vm.$store.state.layout.loading,"dense":"","hide-details":"auto","label":"CNPJ","error-messages":errors},model:{value:(_vm.empresaNova.cpf_cnpj),callback:function ($$v) {_vm.$set(_vm.empresaNova, "cpf_cnpj", $$v)},expression:"empresaNova.cpf_cnpj"}})]}}],null,false,3961304956)}):_vm._e()],1),_c('v-col',{attrs:{"md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"vid":"empresa.plano_id"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('autocomplete-plano',{attrs:{"readonly":!_vm.$store.getters['usuario/temPermissao'](
                          'editar.planos'
                        ),"error-messages":errors},model:{value:(_vm.empresaNova.plano_id),callback:function ($$v) {_vm.$set(_vm.empresaNova, "plano_id", $$v)},expression:"empresaNova.plano_id"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"nome"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                          'mostrar.empresas'
                        ) &&
                        !_vm.$store.getters['usuario/temPermissao'](
                          'editar.empresas'
                        ),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Razão Social","error-messages":errors},model:{value:(_vm.empresaNova.nome),callback:function ($$v) {_vm.$set(_vm.empresaNova, "nome", $$v)},expression:"empresaNova.nome"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"apelido"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                          'mostrar.empresas'
                        ) &&
                        !_vm.$store.getters['usuario/temPermissao'](
                          'editar.empresas'
                        ),"dense":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Nome Fantasia","error-messages":errors},model:{value:(_vm.empresaNova.apelido),callback:function ($$v) {_vm.$set(_vm.empresaNova, "apelido", $$v)},expression:"empresaNova.apelido"}})]}}])})],1)],1)],1)],1),_c('h3',{staticClass:"ma-2"},[_vm._v("Dados para contato")]),_c('v-row',[_c('v-col',{attrs:{"md":"3","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"contato"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"outlined":"","dense":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"error-messages":errors,"label":"Contato"},model:{value:(_vm.empresaNova.contato),callback:function ($$v) {_vm.$set(_vm.empresaNova, "contato", $$v)},expression:"empresaNova.contato"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"Email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"outlined":"","dense":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"error-messages":errors,"label":"Email"},model:{value:(_vm.empresaNova.email),callback:function ($$v) {_vm.$set(_vm.empresaNova, "email", $$v)},expression:"empresaNova.email"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"telefone"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##)#########'),expression:"'(##)#########'"}],attrs:{"outlined":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"dense":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Telefone","error-messages":errors},model:{value:(_vm.empresaNova.telefones[0]),callback:function ($$v) {_vm.$set(_vm.empresaNova.telefones, 0, $$v)},expression:"empresaNova.telefones[0]"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"telefone"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##)#########'),expression:"'(##)#########'"}],attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Telefone","error-messages":errors},model:{value:(_vm.empresaNova.telefones[1]),callback:function ($$v) {_vm.$set(_vm.empresaNova.telefones, 1, $$v)},expression:"empresaNova.telefones[1]"}})]}}])})],1)],1),_c('h3',{staticClass:"mb-2 mt-2"},[_vm._v("Dados do endereço")]),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.logradouro"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"required":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Logradouro","error-messages":errors},model:{value:(_vm.empresaNova.endereco.logradouro),callback:function ($$v) {_vm.$set(_vm.empresaNova.endereco, "logradouro", $$v)},expression:"empresaNova.endereco.logradouro"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"complemento"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Complemento","error-messages":errors},model:{value:(_vm.empresaNova.endereco.complemento),callback:function ($$v) {_vm.$set(_vm.empresaNova.endereco, "complemento", $$v)},expression:"empresaNova.endereco.complemento"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"bairro"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Bairro","error-messages":errors},model:{value:(_vm.empresaNova.endereco.bairro),callback:function ($$v) {_vm.$set(_vm.empresaNova.endereco, "bairro", $$v)},expression:"empresaNova.endereco.bairro"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.numero"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Número","error-messages":errors},model:{value:(_vm.empresaNova.endereco.numero),callback:function ($$v) {_vm.$set(_vm.empresaNova.endereco, "numero", $$v)},expression:"empresaNova.endereco.numero"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.cep"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"CEP","error-messages":errors},model:{value:(_vm.empresaNova.endereco.cep),callback:function ($$v) {_vm.$set(_vm.empresaNova.endereco, "cep", $$v)},expression:"empresaNova.endereco.cep"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('autocomplete-estados',{attrs:{"readonly":_vm.$store.getters['usuario/temPermissao'](
                    'mostrar.empresas'
                  ) &&
                  !_vm.$store.getters['usuario/temPermissao']('editar.empresas')},model:{value:(_vm.estadoId),callback:function ($$v) {_vm.estadoId=$$v},expression:"estadoId"}})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.cidade_id"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('autocomplete-cidades',{attrs:{"estado-id":_vm.estadoId,"readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.empresas'
                    ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"error-messages":errors},model:{value:(_vm.empresaNova.endereco.cidade_id),callback:function ($$v) {_vm.$set(_vm.empresaNova.endereco, "cidade_id", $$v)},expression:"empresaNova.endereco.cidade_id"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12"}},[_c('btn-salvar',{attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao']('editar.empresas'),"carregando":_vm.carregandoSalvar},on:{"click":function($event){return _vm.$emit('salvar', _vm.empresaNova)}}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }